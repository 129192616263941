import { ChangeEvent, useState } from 'react';
import { IDataCarrierIdInput, IPickedTickets } from '../types';
import Components from '../..';
import Pictures from '../../../assets/picture';
import { useTranslation } from 'react-i18next';

interface IProps {
  ticket: IDataCarrierIdInput;
  index: number;
  setInputsState: React.Dispatch<React.SetStateAction<IDataCarrierIdInput[]>>;
  setPickedTickets: React.Dispatch<React.SetStateAction<IPickedTickets[]>>;
}

const Input = (props: IProps) => {
  const { ticket, setInputsState, index } = props;
  const { t } = useTranslation('components');

  const [value, setValue] = useState<string>(ticket.dataCarrierId ?? '');

  const updateInputsState = (param: {
    [key: string]: boolean | string | null;
  }) => {
    setInputsState((prev) =>
      prev.map((input) => {
        if (input.uniqueId === ticket.uniqueId) {
          return { ...input, ...param };
        } else return input;
      })
    );
  };

  const setDataCarrierId = (dataCarrierId: string) => {
    updateInputsState({ dataCarrierId });
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    const dataCarrierId = value;
    setDataCarrierId(dataCarrierId);
  };

  return (
    <>
      <label htmlFor={ticket.uniqueId}>
        {ticket.name} {index + 1}
      </label>
      <div className="berg-components-media-data-aside">
        <input
          name={ticket.uniqueId}
          type="text"
          id={ticket.uniqueId}
          placeholder={ticket.dataCarrierId ? ticket.dataCarrierId : 'TS802FR'}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          minLength={2}
          maxLength={20}
        />
        {value && value.length > 1 && (
          <img
            src={Pictures.Check}
            alt="check"
            className="berg-components-media-data-img"
          />
        )}
        {value && value.length <= 1 && (
          <div className="error">
            <Components.Tooltip content={t('media_data.error_mes')}>
              {
                <img
                  src={Pictures.Error}
                  alt="error"
                  className="berg-components-media-data-img"
                />
              }
            </Components.Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
