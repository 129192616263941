import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogCartPopups from './DialogCartPopups/DialogCartPopups';
import Hooks from '../../hooks';
import { getPopups } from '../../modules/basket/selectors';
import { hidePopups } from '../../modules/basket/slice';

const PopupControl = () => {
  const basketPopups = Hooks.useAppSelector((s) => getPopups(s.basket));
  const dispatch = Hooks.useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const closeHandler = () => {
    dispatch(hidePopups());
  };

  const goToCartHandler = () => {
    navigate('/cart');
    dispatch(hidePopups());
  };

  const goToParking = () => {
    navigate('/activities/parking');
    dispatch(hidePopups());
  };

  const goToList = () => {
    console.log('go to list');
    let listUrl = location.pathname + location.search;
    if (location.search.length === 0) {
      listUrl += '?list=true';
    } else {
      listUrl += '&list=true';
    }

    navigate(listUrl);
    dispatch(hidePopups());
    navigate(0);
  };

  return (
    <DialogCartPopups
      isOpen={basketPopups}
      handleClose={closeHandler}
      handleGoToBasket={goToCartHandler}
      handleContinueShopping={closeHandler}
      handleReturnToList={goToList}
      handleGoToParking={goToParking}
    />
  );
};
export default PopupControl;
