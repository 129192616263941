/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import Icons from '../../assets/icons';
import './Footer.scss';
import Pictures from '../../assets/picture';
import siteSettings from '../../config/siteSettings';
// import regulationSalesPl from '../../assets/regulations/pl-internet-sales-terms-and-conditions.pdf';
// import termsAndConditionsSk from '../../assets/regulations/terms-and-conditions-sk.pdf';
// import generalTermsAndConditionsSk from '../../assets/regulations/general-terms-and-conditions-sk.pdf';

const Footer = () => {
  const { t } = useTranslation('components');

  const year = new Date().getFullYear();

  const linkDlugaPolana = siteSettings.dlugaPolana;

  return (
    <section>
      <div className="footer-component-header">
        <a href={linkDlugaPolana} target="_blank" rel="noreferrer">
          <img src={Pictures.DlugaPolana} alt="Logo Długa Polana" />
        </a>

        {/* <div className="footer-component-header__partners">
          <div className="footer-component-line" />
          <p>{t('footer.partners')}</p>
          <a href="https://tatrysuperski.pl">{t('footer.tatry_super_ski')}</a>
        </div> */}
      </div>

      <div className="footer-component-company-data">
        <p>Stacja Narciarska Długa Polana:</p>
        <p>os. Oleksówki 6a, 34-400 Nowy Targ,</p>
        <a href="tel:182666262">
          <p>tel. 18 266 62 62,</p>
        </a>
        <a href="mailto:biuro@dlugapolana.com">
          <p>biuro@dlugapolana.com</p>
        </a>
        <a href={linkDlugaPolana.concat('/menu-gora/kontakt')}>
          <button>{t('footer.contact')}</button>
        </a>
      </div>

      <hr></hr>
      <div className="footer-component-footer">
        <div className="footer-component-footer__rules">
          <div>
            <a
              href="https://www.dlugapolana.com/polityka-prywatnosci"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.privacy')}
            </a>
            {/* <Link to="/cookies-policy">{t('footer.cookies')}</Link>

            <a href={regulationSalesPl}>
              {t('footer.sales_terms_and_conditions')}
            </a>

            <a href={termsAndConditionsSk}>
              {t('footer.terms_and_conditions')}
            </a>
            <a href={generalTermsAndConditionsSk}>
              {t('footer.general_terms_and_conditions')}
            </a> */}
          </div>
        </div>
        <div className="footer-component-footer__berg">
          <span className="footer-component-footer-copyright">
            {t('footer.copyright', { year })}
          </span>
          <a
            href="http://bergregions.pl"
            target="_blank"
            rel="noreferrer"
            className="footer-component-footer__berg-logo"
          >
            <Icons.LogoBergRegions />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
