import { ReactComponent as Calendar } from './svg/calendar.svg';
import { ReactComponent as CalendarGreyMini } from './svg/calendar-grey.svg';
import { ReactComponent as Adult } from './svg/adult.svg';
import { ReactComponent as Child } from './svg/child.svg';
import { ReactComponent as People } from './svg/people.svg';
import { ReactComponent as InfoCircle } from './svg/info-circle.svg';
import { ReactComponent as Xmark } from './svg/xmark.svg';
import { ReactComponent as Ig } from './svg/ig.svg';
import { ReactComponent as Fb } from './svg/fb.svg';
import { ReactComponent as LengthSkiSlop } from './svg/length_ski_slop.svg';
import { ReactComponent as Mountain } from './svg/mountain.svg';
import { ReactComponent as Skier } from './svg/skier.svg';
import { ReactComponent as ArrowRight } from './svg/arrow-right.svg';
import { ReactComponent as Thermometer } from './svg/thermometer.svg';
import { ReactComponent as Dimensions } from './svg/dimensions.svg';
import { ReactComponent as Depth } from './svg/depth.svg';
import { ReactComponent as AlertAttention } from './svg/alert-attention.svg';
import { ReactComponent as IgCircle } from './svg/ig-circle.svg';
import { ReactComponent as FbCircle } from './svg/fb-circle.svg';
import { ReactComponent as Basket } from './svg/basket.svg';
import { ReactComponent as DoubleBed } from './svg/bed-double.svg';
import { ReactComponent as TV } from './svg/tv.svg';
import { ReactComponent as WiFi } from './svg/wifi.svg';
import { ReactComponent as WC } from './svg/wc.svg';
import { ReactComponent as Bed } from './svg/bed.svg';
import { ReactComponent as Conditioning } from './svg/conditioning.svg';
import { ReactComponent as CzechFlag } from './svg/czech-flag.svg';
import { ReactComponent as EnglandFlag } from './svg/england-flag.svg';
import { ReactComponent as PolandFlag } from './svg/poland-flag.svg';
import { ReactComponent as SlovakiaFlag } from './svg/slovakia-flag.svg';
import { ReactComponent as CalendarGrey } from './svg/calendar-grey.svg';
import { ReactComponent as KingBed } from './svg/king-bed.svg';
import { ReactComponent as Clock } from './svg/clock.svg';
import { ReactComponent as LocationPin } from './svg/location-pin.svg';
import { ReactComponent as Smartphone } from './svg/smartphone.svg';
import { ReactComponent as Tick } from './svg/tick.svg';
import { ReactComponent as ShoppingBag } from './svg/shopping-bag.svg';
import { ReactComponent as QrOravski } from './svg/qr-oravski.svg';
import { ReactComponent as QrRail } from './svg/qr-rail.svg';
import { ReactComponent as QrMuseum } from './svg/qr-museum.svg';
import { ReactComponent as QrTwardoszyn } from './svg/qr-twardzoszyn.svg';
import { ReactComponent as QrKoscielisko } from './svg/qr-koscielisko.svg';
import { ReactComponent as LogoBergRegions } from './svg/logoBergRegions.svg';
import { ReactComponent as Delete } from './svg/delete.svg';
import { ReactComponent as AlertInfo } from './svg/alert-info.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as Info } from './svg/icon-info.svg';
import { ReactComponent as Back } from './svg/back.svg';
import { ReactComponent as BackMobile } from './svg/back-mobile.svg';
import { ReactComponent as GreenTick } from './svg/green-tick.svg';
import { ReactComponent as ArrowLeft } from './svg/arrow_left.svg';
import { ReactComponent as FbCircleWinter } from './svg/FB-circle-winter.svg';
import { ReactComponent as FbCircleSummer } from './svg/FB-circle-summer.svg';
import { ReactComponent as IgCircleWinter } from './svg/IG-circle-winter.svg';
import { ReactComponent as IgCircleSummer } from './svg/IG-circle-summer.svg';
import { ReactComponent as Telephone } from './svg/telephone.svg';
import { ReactComponent as AlertAttentionRed } from './svg/alert_attention_red.svg';
import { ReactComponent as Star } from './svg/star.svg';
import { ReactComponent as StarPerry } from './svg/star-perry.svg';
import { ReactComponent as MyLocation } from './svg/my-location.svg';
import { ReactComponent as Icon2d3d } from './svg/2d-3d.svg';

const Icons = {
  Calendar,
  CalendarGreyMini,
  Adult,
  Child,
  People,
  InfoCircle,
  Xmark,
  Ig,
  Fb,
  LengthSkiSlop,
  Mountain,
  Skier,
  ArrowRight,
  Thermometer,
  Dimensions,
  Depth,
  AlertAttention,
  AlertInfo,
  IgCircle,
  FbCircle,
  Basket,
  DoubleBed,
  TV,
  WiFi,
  WC,
  Bed,
  Conditioning,
  CzechFlag,
  EnglandFlag,
  PolandFlag,
  SlovakiaFlag,
  CalendarGrey,
  KingBed,
  Clock,
  LocationPin,
  Smartphone,
  Tick,
  ShoppingBag,
  QrOravski,
  QrRail,
  QrMuseum,
  QrTwardoszyn,
  QrKoscielisko,
  LogoBergRegions,
  Delete,
  Warning,
  Info,
  Back,
  BackMobile,
  GreenTick,
  ArrowLeft,
  FbCircleWinter,
  FbCircleSummer,
  IgCircleWinter,
  IgCircleSummer,
  Telephone,
  AlertAttentionRed,
  Star,
  StarPerry,
  MyLocation,
  Icon2d3d,
} as const;

export type TIcons = keyof typeof Icons;

export default Icons;
