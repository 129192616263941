import { IParking } from '../../modules/parking/models';
import Parking from './Parking';

interface IProps {
  parking: IParking[];
}

const ParkingTemplate = (props: IProps) => {
  const { parking } = props;

  return (
    <div className="berg-components-therms-template">
      {parking?.map((p) => (
        <Parking key={p.id} parking={p} />
      ))}
    </div>
  );
};

export default ParkingTemplate;
