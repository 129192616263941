import moment from 'moment';
import { useEffect, useState } from 'react';
import Components from '../../components';
import { LocalStorageKeys } from '../../config/globalVariables';
import Hooks from '../../hooks';
import { getBillboards } from '../../modules/location/billboards/selectors';

const Billboard = () => {
  const [isBillboardOpen, setIsBillboardOpen] = useState<boolean>(false);

  const billboards = Hooks.useAppSelector((s) => getBillboards(s.billboards));

  const handleClose = () => {
    localStorage.setItem(LocalStorageKeys.isBillboardDismissed, 'true');

    setIsBillboardOpen(false);
  };

  useEffect(() => {
    if (!billboards?.length) {
      return;
    }

    const billboardFirstOccurrenceTime = localStorage.getItem(
      LocalStorageKeys.billboardFirstOccurrenceTime
    );

    const isBillboardDismissed =
      localStorage.getItem(LocalStorageKeys.isBillboardDismissed) === 'true';

    const duration = moment().diff(
      moment(billboardFirstOccurrenceTime),
      'hours'
    );

    if (
      billboardFirstOccurrenceTime === null ||
      duration >= 24 ||
      !isBillboardDismissed
    ) {
      localStorage.setItem(
        LocalStorageKeys.billboardFirstOccurrenceTime,
        moment().format()
      );

      localStorage.setItem(LocalStorageKeys.isBillboardDismissed, 'false');

      setIsBillboardOpen(true);
    }
  }, [billboards?.length]);

  if (!billboards?.length) {
    return null;
  }

  return (
    <Components.Dialog
      isOpen={isBillboardOpen}
      handleClose={handleClose}
      buttonClassName="berg-components-billboard__close-btn"
    >
      <img
        className="berg-components-billboard__image"
        src={billboards[0].url}
      />
    </Components.Dialog>
  );
};

export default Billboard;
