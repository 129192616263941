import Components from '../..';
import './BergPageContentWrapper.scss';

interface IProps extends React.ComponentPropsWithoutRef<'div'> {
  header: string;
  children: React.ReactNode;
}

const BergPageContentWrapper = ({ children, header, ...props }: IProps) => (
  <>
    <Components.Portal id="sub-header">
      <div className="berg-pages-start__sub-header-placeholder ">
        <h2>{header}</h2>
      </div>
    </Components.Portal>
    <div className={['berg-page-content-wrapper'].join(' ')} {...props}>
      {children}
    </div>
  </>
);

export default BergPageContentWrapper;
