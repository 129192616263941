import React from 'react';
import { useTranslation } from 'react-i18next';
import Hooks from '../../../hooks';
import { getBasketItems } from '../../../modules/basket/selectors';
import Components from '../..';
import { getLastItemId } from '../../../modules/basket/selectors';
import './DialogCartPopups.scss';
import BergDevider from '../../Storybook/BergDevider/BergDevider';
import visibleOnRelease from '../../../config/visibleOnRelease';

interface IProps {
  isOpen: boolean;
  handleClose?: (param?: any) => void;
  handleContinueShopping?: (param?: any) => void;
  handleGoToBasket?: (param?: any) => void;
  handleReturnToList?: (param?: any) => void;
  handleGoToParking?: (param?: any) => void;
}

const DialogCartPopups = (props: IProps) => {
  const {
    isOpen,
    handleClose,
    handleContinueShopping,
    handleGoToBasket,
    handleReturnToList,
    handleGoToParking,
  } = props;

  const basketItems = Hooks.useAppSelector((s) => getBasketItems(s.basket));
  const lastItemId = Hooks.useAppSelector((s) => getLastItemId(s.basket));
  const { t } = useTranslation('components');

  // const changeDataFormat = (dateFormat: string | undefined) => {
  //   const checkDate = dateFormat !== undefined ? dateFormat : '2023-02-02';
  //   const dateArr = checkDate.split('-');
  //   const day = dateArr[2];
  //   const month = dateArr[1];
  //   const yare = dateArr[0];

  //   const newDateFormat = `${day}.${month}.${yare}`;

  //   return newDateFormat;
  // };

  // const checkCategory = (productType: string) => {
  //   switch (productType) {
  //     case 'SkiPass':
  //       return t('dialog_cart_popups.products.ski_pass');
  //       break;
  //     case 'SkiRental':
  //       return t('dialog_cart_popups.products.ski_rental');
  //       break;
  //     case 'SkiSchool':
  //       return t('dialog_cart_popups.products.ski_school');
  //       break;
  //     case 'Accommodation':
  //       return t('dialog_cart_popups.products.accommodation');
  //       break;
  //     case 'Therms':
  //       return t('dialog_cart_popups.products.therms');
  //       break;
  //     default:
  //       return null;
  //   }
  // };

  if (!isOpen) {
    return null;
  }

  console.log(basketItems);

  return (
    // <Components.Portal id="dialog">
    //   <div className="berg-components-dialog-backdrop" onClick={handleClose} />
    //   <div className="berg-components-dialog-cart-popups">
    //     <div className="berg-components-dialog-cart-popups__info">
    //       {season === Season.Winter ? (
    //         <img src={Pictures.WinterSuccess} alt="success" />
    //       ) : (
    //         <img src={Pictures.SummerSuccess} alt="success" />
    //       )}

    //       <h3>{t('dialog_cart_popups.title')}</h3>
    //       <ul>
    //         {basketItems
    //           .filter((item) => item.id === lastItemId)
    //           .map((product) => {
    //             if (product.itemType === 'SkiPass') {
    //               return (
    //                 <li key={product.id}>
    //                   <p>
    //                     <span>{changeDataFormat(product.validityDate)} </span> -{' '}
    //                     {checkCategory(product.itemType)}{' '}
    //                     {product.quantity > 1 && product.quantity} -{' '}
    //                     {product.item.name}
    //                   </p>
    //                 </li>
    //               );
    //             }
    //             if (product.itemType === 'Accommodation') {
    //               return (
    //                 <li key={product.id}>
    //                   <p>
    //                     <span>
    //                       {changeDataFormat(product.startDate)} -{' '}
    //                       {changeDataFormat(product.endDate)}
    //                     </span>{' '}
    //                     - {checkCategory(product.itemType)} {product.item.name}
    //                     <BergDevider color="lighter-grey" />
    //                   </p>
    //                 </li>
    //               );
    //             }
    //             if (product.itemType === 'Parking') {
    //               return (
    //                 <li key={product.id}>
    //                   <p>
    //                     <span>{changeDataFormat(product.date)}</span> -{' '}
    //                     {checkCategory(product.itemType)} {product.item.name}
    //                   </p>
    //                 </li>
    //               );
    //             }
    //           })}
    //       </ul>
    //     </div>

    //     {basketItems
    //       .filter((item) => item.id === lastItemId)
    //       .map((product) => {
    //         if (product.itemType === 'Accommodation') {
    //           return (
    //             <div
    //               key={product.id}
    //               className="berg-components-dialog-cart-popups__buttons"
    //             >
    //               {visibleOnRelease.parking && (
    //                 <>
    //                   <button
    //                     onClick={handleGoToParking}
    //                     className="berg-components-dialog-cart-popups__buttons-btn-two buttons-accommodation"
    //                   >
    //                     {t('dialog_cart_popups.buttons_name.go_to_parking')}
    //                   </button>
    //                   <BergDevider color="lighter-grey" />
    //                 </>
    //               )}
    //               <button
    //                 onClick={handleReturnToList}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-one"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.return_to_list')}
    //               </button>
    //               <button
    //                 onClick={handleContinueShopping}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-one"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.return_to_offer')}
    //               </button>
    //               <button
    //                 onClick={handleGoToBasket}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-two"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.go_to_basket')}
    //               </button>
    //             </div>
    //           );
    //         } else if (product.itemType === 'Parking') {
    //           return (
    //             <div
    //               key={product.id}
    //               className="berg-components-dialog-cart-popups__buttons"
    //             >
    //               <button
    //                 onClick={handleContinueShopping}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-one"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.return_to_offer')}
    //               </button>
    //               <button
    //                 onClick={handleGoToBasket}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-two"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.go_to_basket')}
    //               </button>
    //             </div>
    //           );
    //         } else {
    //           return (
    //             <div
    //               key={product.id}
    //               className="berg-components-dialog-cart-popups__buttons"
    //             >
    //               <button
    //                 onClick={handleContinueShopping}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-one"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.return_to_offer')}
    //               </button>
    //               <button
    //                 onClick={handleGoToParking}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-two"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.go_to_parking')}
    //               </button>
    //               <button
    //                 onClick={handleGoToBasket}
    //                 className="berg-components-dialog-cart-popups__buttons-btn-two"
    //               >
    //                 {t('dialog_cart_popups.buttons_name.go_to_basket')}
    //               </button>
    //             </div>
    //           );
    //         }
    //       })}
    //   </div>
    // </Components.Portal>
    <Components.Portal id="dialog">
      <div className="berg-components-dialog-backdrop" onClick={handleClose} />

      <div className="berg-components-dialog-cart-popups">
        <div className="berg-components-dialog-cart-popups__info">
          <h3>{t('dialog_cart_popups.header')}</h3>
          <Components.BergDevider color="ligth-grey" />
          <img
            src={require('../../../assets/picture/basket.png')}
            alt="basket"
          />
        </div>

        <Components.BergDevider color="ligth-grey" />
        {basketItems
          .filter((item) => item.id === lastItemId)
          .map((product) => {
            if (product.itemType === 'Accommodation') {
              return (
                <div
                  key={product.id}
                  className="berg-components-dialog-cart-popups__buttons"
                >
                  {visibleOnRelease.parking && (
                    <>
                      <button
                        onClick={handleGoToParking}
                        className="berg-components-dialog-cart-popups__buttons-btn-two buttons-accommodation"
                      >
                        {t('dialog_cart_popups.buttons_name.go_to_parking')}
                      </button>
                      <BergDevider color="lighter-grey" />
                    </>
                  )}
                  <button
                    onClick={handleReturnToList}
                    className="berg-components-dialog-cart-popups__buttons-btn-one"
                  >
                    {t('dialog_cart_popups.buttons_name.return_to_list')}
                  </button>
                  <button
                    onClick={handleContinueShopping}
                    className="berg-components-dialog-cart-popups__buttons-btn-one"
                  >
                    {t('dialog_cart_popups.buttons_name.return_to_offer')}
                  </button>
                  <button
                    onClick={handleGoToBasket}
                    className="berg-components-dialog-cart-popups__buttons-btn-two"
                  >
                    {t('dialog_cart_popups.buttons_name.go_to_basket')}
                  </button>
                </div>
              );
            } else if (product.itemType === 'Parking') {
              return (
                <div
                  key={product.id}
                  className="berg-components-dialog-cart-popups__buttons"
                >
                  <button
                    onClick={handleContinueShopping}
                    className="berg-components-dialog-cart-popups__buttons-btn-one"
                  >
                    {t('dialog_cart_popups.buttons_name.return_to_offer')}
                  </button>
                  <button
                    onClick={handleGoToBasket}
                    className="berg-components-dialog-cart-popups__buttons-btn-two"
                  >
                    {t('dialog_cart_popups.buttons_name.go_to_basket')}
                  </button>
                </div>
              );
            } else {
              return (
                <div
                  key={product.id}
                  className="berg-components-dialog-cart-popups__buttons"
                >
                  <button
                    onClick={handleContinueShopping}
                    className="berg-components-dialog-cart-popups__buttons-btn-one"
                  >
                    {t('dialog_cart_popups.buttons_name.return_to_offer')}
                  </button>
                  <button
                    onClick={handleGoToParking}
                    className="berg-components-dialog-cart-popups__buttons-btn-two"
                  >
                    {t('dialog_cart_popups.buttons_name.go_to_parking')}
                  </button>
                  <button
                    onClick={handleGoToBasket}
                    className="berg-components-dialog-cart-popups__buttons-btn-two"
                  >
                    {t('dialog_cart_popups.buttons_name.go_to_basket')}
                  </button>
                </div>
              );
            }
          })}
      </div>
    </Components.Portal>
  );
};

export default DialogCartPopups;
