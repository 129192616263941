import Header from './Header/Header';
import Footer from './Footer/Footer';
import Meta from './Meta/Meta';
import Navigation from './Navigation/Navigation';
import Search from './Search/Search';
import Alerts from './Alerts/Alerts';
import ContactDetails from './ContactDetails/ContactDetails';
import StickyCTA from './StickyCTA/StickyCTA';
import ContactForm from './ContactForm/ContactForm';
import SkiSlope from './SkiSlope/SkiSlope';
import PoolDescription from './PoolDescription/PoolDescription';
import Dropdown from './Dropdown/Dropdown';
import BasketStickyCTA from './BasketStickyCTA/BasketStickyCTA';
import RoomCard from './RoomCard/RoomCard';
import Ticket from './PassesAndTickets/Ticket/Ticket';
import PassesAndTickets from './PassesAndTickets/PassesAndTickets';
import NumberCounter from './NumberCounter/NumberCounter';
import RoomDetails from './RoomDetails/RoomDetails';
import Banner from './Banner/Banner';
import OptionsSwitch from './OptionsSwitch/OptionsSwitch';
import SeasonSelector from './SeasonSelector/SeasonSelector';
import Dialog from './Dialog/Dialog';
import Spinner from './Spinner/Spinner';
import RangeCalendar from './RangeCalendar/RangeCalendar';
import Tooltip from './Tooltip/Tooltip';
import AboutRegionAdditionalInformation from './AboutRegionAdditionalInformation/AboutRegionAdditionalInformation';
import Portal from './Portal/Portal';
import Tabs from './Tabs/Tabs';
import About from './About/About';
import ChoiceNumberPersons from './ChoiceNumber/ChoiceNumberPersons';
import Loader from './Loader/Loader';
import SingleCalendar from './SingleCalendar/SingleCalendar';
import ChoiceNumberChildren from './ChoiceNumber/ChoiceNumberChildren';
import FilterBar from './FilterBar/FilterBar';

import MenuTemplate from './MenuTemplate/MenuTemplate';

import BergPageContentWrapper from './Storybook/BergPageContentWrapper/BergPageContentWrapper';
import BergButton from './Storybook/BergButton/BergButton';
import BergCheckbox from './Storybook/BergCheckbox/BergCheckbox';
import BergStack from './Storybook/BergStack/BergStack';
import BergTextInput from './Storybook/BergTextinput/BergTextInput';
import BergDevider from './Storybook/BergDevider/BergDevider';
import BergGridWrapper from './Storybook/BergGrid/BergGridWrapper';
import OrderSummaryHeader from './Order/OrderSummaryHeader/OrderSummaryHeader';

import ThanksForOrder from './ThanksForOrder/ThanksForOrder';
import DialogCartPopups from './Dialog/DialogCartPopups/DialogCartPopups';
import PopupControl from './Dialog/PopupControl';
import StepsAxis from './StepsAxis/StepsAxis';
import OrderTable from './OrderTable/OrderTable';
import OrderSummaryFooter from './Order/OrderSummaryFooter/OrderSummaryFooter';
import OrderContactData from './Order/OrderContactData/OrderContactData';
import RoomFullCard from './RoomFullCard/RoomFullCard';
import CollapseList from './CollapseList/CollapseList';
import Pagination from './Pagination/Pagination';
import ParkingTemplate from './Parking/ParkingTemplate';

const Components = {
  Header,
  Search,
  Footer,
  Meta,
  Navigation,
  Alerts,
  StickyCTA,
  ContactForm,
  ContactDetails,
  SkiSlope,
  PoolDescription,
  Dropdown,
  BasketStickyCTA,
  RoomCard,
  PassesAndTickets,
  Ticket,
  NumberCounter,
  RoomDetails,
  Banner,
  OptionsSwitch,
  SeasonSelector,
  Dialog,
  Spinner,
  RangeCalendar,
  SingleCalendar,
  Tooltip,
  AboutRegionAdditionalInformation,
  Portal,
  Tabs,
  About,
  ChoiceNumberPersons,
  Loader,
  ChoiceNumberChildren,
  FilterBar,

  MenuTemplate,

  BergPageContentWrapper,
  BergButton,
  BergCheckbox,
  BergStack,
  BergTextInput,
  BergDevider,
  BergGridWrapper,
  OrderSummaryHeader,

  ThanksForOrder,
  DialogCartPopups,
  PopupControl,
  StepsAxis,
  OrderTable,
  OrderSummaryFooter,
  OrderContactData,
  RoomFullCard,
  CollapseList,
  Pagination,
  ParkingTemplate,
};

export default Components;
