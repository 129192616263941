import { useTranslation } from 'react-i18next';
import Carousel from '../../components/ArrowsCarousel/ArrowsCarousel';

const PictureCarousel = () => {
  const { t } = useTranslation('pages');

  return (
    <Carousel>
      <img
        src="/assets/landingpage/carousel/landipage-carousel-1.jpg"
        alt={t('accommodation.standard_room.carousel.img_alt_room_3')}
      />
      <img
        src="/assets/landingpage/carousel/landipage-carousel-2.jpg"
        alt={t('accommodation.standard_room.carousel.img_alt_room_4')}
      />
      <img
        src="/assets/landingpage/carousel/landipage-carousel-3.jpg"
        alt={t('accommodation.standard_room.carousel.img_alt_room_5')}
      />
      <img
        src="/assets/landingpage/carousel/landipage-carousel-4.jpg"
        alt={t('accommodation.standard_room.carousel.img_alt_room_5')}
      />
      <img
        src="/assets/landingpage/carousel/landipage-carousel-5.jpg"
        alt={t('accommodation.standard_room.carousel.img_alt_room_5')}
      />
      <img
        src="/assets/landingpage/carousel/landipage-carousel-6.jpg"
        alt={t('accommodation.standard_room.carousel.img_alt_room_5')}
      />
    </Carousel>
  );
};
export default PictureCarousel;
