interface ISiteSettings {
  instagramUrl?: string;
  facebookUrl?: string;
  dlugaPolana: string;
}

const siteSettings: ISiteSettings = {
  dlugaPolana: 'https://www.dlugapolana.com',
};

export default siteSettings;
