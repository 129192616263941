import React from 'react';
import './BergButton.scss';
interface IButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  color?:
    | 'primary'
    | 'secondary'
    | 'red'
    | 'primary-dark'
    | 'gray-400'
    | 'white'
    | 'white-border';

  backgroundColor?: string;

  label: string;

  onClick?: (e: React.SyntheticEvent) => void;
  type?: 'button' | 'submit' | 'reset';
}

const BergButton = ({
  color,
  backgroundColor,
  label,
  type,
  ...rest
}: IButtonProps) => {
  const colorClass = `berg-button__${color ?? 'primary'}`;
  return (
    <button
      type={type}
      className={['berg-button', colorClass].join(' ')}
      style={{ backgroundColor }}
      {...rest}
    >
      {label}
    </button>
  );
};

export default BergButton;
