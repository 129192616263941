import { Trans, useTranslation } from 'react-i18next';
import Components from '../../components';
import TextArea from '../../components/ContactForm/TextArea';
import { useState } from 'react';
import getFormData from '../../utils/getFormData';
import { sendContactFormLp } from '../../modules/location/contact/operations';
import { IContactFormLp } from '../../modules/location/details/models/contact';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../components/Tooltip/Tooltip';

const LandingpageForm = () => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const [readRegulations, setReadRegulations] = useState<boolean>(false);
  const handleReadRegulations = () => {
    setReadRegulations((value) => !value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const values = getFormData(event) as unknown as IContactFormLp;

    sendContactFormLp(values).then((status) => {
      if (status == 200) {
        console.log('success');
        setShouldReset(true);
        navigate('/promo/form/thanks');
      } else {
        console.log('fail');
      }
    });
    setShouldReset(false);
  };

  return (
    <>
      <Components.Portal id="header">
        <div className="berg-page-landingpage__header">
          <div>
            <h1>{t('landingpage.header')}</h1>
            <div className="berg-page-landingpage__header--promo">
              {t('landingpage.promo')}
            </div>
          </div>
        </div>
      </Components.Portal>

      <div key={`${shouldReset}`} className="berg-components-landingpage-form">
        <div>
          <h1>{t('landingpage_form.header')}</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <div className="berg-components-landingpage-form__input">
                <label htmlFor="contact-name">
                  {t('landingpage_form.name')}
                </label>
                <input
                  type="text"
                  id="contact-name"
                  name="name"
                  placeholder={t('landingpage_form.name_placeholder')}
                  required
                />
              </div>
              <div className="berg-components-landingpage-form__input">
                <label htmlFor="contact-email">
                  {t('landingpage_form.email')}
                </label>
                <input
                  type="email"
                  name="mail"
                  id="contact-email"
                  placeholder={t('landingpage_form.email_placeholder')}
                  required
                />
              </div>
              <div className="berg-components-landingpage-form__input">
                <label htmlFor="contact-phone">
                  {t('landingpage_form.phone')}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="contact-phone"
                  placeholder={t('landingpage_form.phone_placeholder')}
                />
              </div>
              <div className="berg-components-landingpage-form__input">
                <label htmlFor="contact-date">
                  {t('landingpage_form.date')}
                </label>
                <input
                  type="text"
                  name="preferredStayDay"
                  id="contact-date"
                  placeholder={t('landingpage_form.date_placeholder')}
                  required
                />
              </div>
              <TextArea
                label={t('landingpage_form.text_area')}
                name="message"
                placeholder={t('landingpage_form.text_area_placeholder')}
                required={false}
              />
              <Components.BergCheckbox
                name="readRegulations"
                onValueChange={handleReadRegulations}
                label={
                  <Trans
                    ns="pages"
                    i18nKey="landingpage_form.checkbox"
                    components={{
                      a: <a href="/privacy-policy"></a>,
                    }}
                  ></Trans>
                }
              />
            </div>
            {readRegulations ? (
              <Components.BergButton
                color="primary"
                label={t('landingpage_form.submit')}
              ></Components.BergButton>
            ) : (
              <Tooltip content={t('landingpage_form.approval_regulations')}>
                <Components.BergButton
                  color="gray-400"
                  label={t('landingpage_form.submit')}
                  disabled={true}
                ></Components.BergButton>
              </Tooltip>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default LandingpageForm;
