import Components from '../../components';
import './Landingpage.scss';
import { Trans, useTranslation } from 'react-i18next';
import Icons from '../../assets/icons';
import PictureCarousel from './PictureCarousel';
import { useNavigate } from 'react-router-dom';

const Landingpage = () => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();

  const pricesWitsBreakfast = [
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_0'],
      price: '99 €',
    },
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_1'],
      price: '129 €',
    },
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_2'],
      price: '159 €',
    },
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_0_single_bed'],
      price: '89 €',
    },
  ];

  const pricesWitsFood = [
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_0'],
      price: '114 €',
    },
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_1'],
      price: '144 €',
    },
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_2'],
      price: '174 €',
    },
    {
      roomType: t('landingpage.prices_room', {
        returnObjects: true,
      })['room_2_0_single_bed'],
      price: '104 €',
    },
  ];

  return (
    <>
      <Components.Portal id="header">
        <div className="berg-page-landingpage__header">
          <h1>{t('landingpage.header')}</h1>
          <div className="berg-page-landingpage__header--promo">
            {t('landingpage.promo')}
          </div>
        </div>
      </Components.Portal>
      <div className="berg-page-landingpage">
        <div className="berg-page-landingpage__promo-desc">
          <div>
            <Trans
              i18nKey="landingpage.promo_desc"
              ns="pages"
              components={{
                strong: <strong />,
                p: <p />,
                ul: <ul />,
                li: <li />,
                br: <br />,
              }}
            />
            <div className="berg-page-landingpage__promo-desc--button">
              <Components.BergButton
                color="primary"
                label={t('landingpage.submit')}
                onClick={() => navigate('/promo/form')}
              ></Components.BergButton>
              <div>
                <Icons.Telephone />
                <a href="tel:+421435504601">+421 43 550 46 01</a>
              </div>
            </div>
          </div>
          <PictureCarousel />
        </div>
        <div className="berg-page-landingpage__price-list">
          <h2>{t('landingpage.price_list')}</h2>
          <p>{t('landingpage.price_list_desc')}</p>
        </div>
        <div className="berg-styles-pricing-table">
          <div className="berg-styles-pricing-table__container">
            <table>
              <thead>
                <tr>
                  <Trans
                    ns="pages"
                    i18nKey="landingpage.table_header_breakfast"
                  >
                    <th colSpan={2}>CENNIK ZAKWATEROWANIA ZE ŚNIADANIEM</th>
                  </Trans>
                </tr>
                <tr>
                  <Trans ns="pages" i18nKey="landingpage.table_headers">
                    <th>typ</th>
                    <th>cena</th>
                  </Trans>
                </tr>
              </thead>
              <tbody>
                {pricesWitsBreakfast.map((price, index) => (
                  <tr key={index}>
                    <td>{price.roomType}</td>
                    <td>{price.price}</td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </table>
          </div>
          <div className="berg-styles-pricing-table__container">
            <table>
              <thead>
                <tr>
                  <Trans ns="pages" i18nKey="landingpage.table_header_food">
                    <th colSpan={2}>CENNIK ZAKWATEROWANIA ZE ŚNIADANIEM</th>
                  </Trans>
                </tr>
                <tr>
                  <Trans ns="pages" i18nKey="landingpage.table_headers">
                    <th>typ</th>
                    <th>cena</th>
                  </Trans>
                </tr>
              </thead>
              <tbody>
                {pricesWitsFood.map((price, index) => (
                  <tr key={index}>
                    <td>{price.roomType}</td>
                    <td>{price.price}</td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landingpage;
