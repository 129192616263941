import moment from 'moment';
import {
  createContext,
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Components from '..';
import Icons from '../../assets/icons';
import Hooks from '../../hooks';
import { IDropdownOption } from '../Dropdown/Dropdown';
import BergStack from '../Storybook/BergStack/BergStack';
import Tooltip from '../Tooltip/Tooltip';
import './FilterBar.scss';

interface IProps {
  typeDropdown?: IDropdownOption[];
  defaultDropdownOptionValue?: string;
  label: string;
  Calendar: ReactNode;
  onTypeChange?: (value: string) => void;
  date: string | null;
  resetDate: MouseEventHandler<HTMLButtonElement>;
  tomorrowDate?: MouseEventHandler<HTMLButtonElement>;
}

interface IFilterBarContext {
  setIsCalendarVisible?: Dispatch<SetStateAction<boolean>>;
}

export const FilterBarContext = createContext<IFilterBarContext>({});

const FilterBar = (props: IProps) => {
  const {
    typeDropdown,
    label,
    Calendar,
    onTypeChange,
    date,
    resetDate,
    defaultDropdownOptionValue,
    tomorrowDate,
  } = props;

  const { t } = useTranslation(['components', 'common']);

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const toggleCalendar = () => {
    setIsCalendarVisible((prev) => !prev);
  };

  const calendarRef = Hooks.useOutsideClick({
    shouldWatch: isCalendarVisible,
    callback: toggleCalendar,
  });

  return (
    <FilterBarContext.Provider value={{ setIsCalendarVisible }}>
      <BergStack
        className="berg-components-filter-bar"
        direction={{ mobile: 'column', desktop: 'row' }}
        spacing={5}
        justifyContent="flex-start"
        alignItems="center"
      >
        <p>{t('components:filter_bar.when')}</p>
        <button
          onClick={resetDate}
          className="berg-components-filter-bar__button--today"
        >
          {t('components:filter_bar.today')}
        </button>
        <button
          onClick={tomorrowDate}
          className="berg-components-filter-bar__button--tomorrow"
        >
          {t('components:filter_bar.tomorrow')}
        </button>
        <div
          ref={calendarRef}
          className="berg-components-filter-bar__period-wrapper"
        >
          <div
            className="berg-components-filter-bar__period"
            onClick={toggleCalendar}
          >
            <Icons.Calendar />
            <span className="berg-components-filter-bar__period-dates">
              {date ? moment(date).format('DD.MM.YYYY') : t('common:choose')}
            </span>
            <div className="berg-components-filter-bar__period-arrow" />
          </div>
          {isCalendarVisible && (
            <div className="berg-components-filter-bar__calendar">
              {Calendar}
            </div>
          )}
        </div>
        <p className="berg-components-filter-bar__label">{label}</p>
        {typeDropdown ? (
          <Tooltip content={null}>
            <div className="berg-components-filter-bar__dropdown">
              <Components.Dropdown
                defaultValue={defaultDropdownOptionValue}
                options={typeDropdown}
                onChange={onTypeChange}
                name="skiPassType"
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip content={t('components:filter_bar.select_date')}>
            <div className="berg-components-filter-bar__dropdown">
              <Components.Dropdown
                defaultValue={defaultDropdownOptionValue}
                options={typeDropdown}
                onChange={onTypeChange}
                name="skiPassType"
              />
            </div>
          </Tooltip>
        )}
      </BergStack>
    </FilterBarContext.Provider>
  );
};

export default FilterBar;
