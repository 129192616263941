import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Components from '../../components';
import { IColumn } from '../../components/OrderTable/OrderTable';
import Hooks from '../../hooks';
import useCartSteps from '../../hooks/useCartSteps';
import { fetchOrderDetails } from '../../modules/order/operations';
import { getOrderDetails } from '../../modules/order/selectors';
import './PaymentStatus.scss';
import '../../pages/cart/cart-summary/CartSummary.scss';
import CollapseList from '../../components/CollapseList/CollapseList';
import moment from 'moment';
import calculatePrice from '../../utils/calculatePrice';

const PaymentStatus = () => {
  const { t } = useTranslation(['components', 'pages', 'common']);
  const dispatch = Hooks.useAppDispatch();
  const orderDetails = Hooks.useAppSelector((s) => getOrderDetails(s.order));
  const queryParams = Hooks.useQueryParams();
  const orderId = queryParams.get('OrderID');

  const { steps, currentStep } = useCartSteps(4);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrderDetails(orderId));
    }
  }, [dispatch, orderId]);

  const skiPassTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row.consumerCategoryName}{' '}
          <span className="cart-summary-ticket-info">{row.productName}</span>
          {row.dataCarrierIds.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.skipass_additional_info')}
              items={row.dataCarrierIds}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row.orderItemPrice.grossAmount} ${row.orderItemPrice.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.validityDate).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.orderItemPrice.grossAmount, row.quantity)} ${
          row.orderItemPrice.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const accommodationTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row.consumerCategoryName}
          <span className="cart-summary-ticket-info">{row.productName}</span>
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.quantity_room'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.extra_bed'),
      rows: (row) => row.additionalGuestsCount,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number_of_nights'),
      rows: (row) => moment(row.endDate).diff(row.startDate, 'days'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => {
        return (
          moment(row.startDate).format('DD.MM.YYYY') +
          ' - ' +
          moment(row.endDate).format('DD.MM.YYYY')
        );
      },
      thClassName: 'visible@l',
      tdClassName: 'w36@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.orderItemPrice.grossAmount, row.quantity)} ${
          row.orderItemPrice.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const parkingTable: IColumn[] = [
    {
      header: t('pages:cart_summary.table_headers.product_name'),
      rows: (row) => (
        <div>
          {row.consumerCategoryName}{' '}
          {/* <span className="cart-summary-ticket-info">{row.productName}</span> */}
          {row.licencePlates.length > 0 && (
            <CollapseList
              buttonName={t('pages:cart_summary.parking_additional_info')}
              items={row.licencePlates}
            />
          )}
        </div>
      ),
      tdClassName: 'full@sm w40@l',
    },
    {
      header: t('pages:cart_summary.table_headers.price'),
      rows: (row) =>
        `${row.orderItemPrice.grossAmount} ${row.orderItemPrice.currency}`,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.number'),
      rows: (row) => row.quantity,
      thClassName: 'visible@l',
      tdClassName: 'w50@sm w10@l',
    },
    {
      header: t('pages:cart_summary.table_headers.date'),
      rows: (row) => moment(row.data).format('DD.MM.YYYY'),
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w15@l',
    },
    {
      header: t('pages:cart_summary.table_headers.sum'),
      rows: (row) =>
        `${calculatePrice(row.orderItemPrice.grossAmount, row.quantity)} ${
          row.orderItemPrice.currency
        }`,
      thClassName: 'visible@l',
      tdClassName: 'w30@sm w10@l nowrap',
    },
    {
      rows: () => <></>,
      thClassName: 'visible@l',
      tdClassName: 'w10@sm w5@l',
    },
  ];

  const checkStatus = (status: string) => {
    switch (status) {
      case 'paid':
        return '#32ba7c';
      case 'unpaid':
        return 'red';
      case 'failed':
        return 'red';
      case 'pending':
        return 'rgb(243 201 40)';
      default:
        return 'black';
    }
  };

  console.log(orderDetails?.parkingOrder);

  return (
    <Components.BergPageContentWrapper
      header={t('pages:order_confirmation.payment_header')}
    >
      <Components.StepsAxis steps={steps} currentStep={currentStep} />
      <div className="payment-status-wrapper">
        <Components.BergStack direction="column">
          <Components.OrderSummaryHeader
            orderDetails={orderDetails}
          ></Components.OrderSummaryHeader>
          <Components.BergStack
            mt={5}
            direction="row"
            className="payment-status-wrapper__payment-box"
            alignItems="center"
          >
            <div className="payment-status-wrapper__item-header">
              {t('components:order_summary_header.payment_status_label')}
            </div>
            <div
              className="payment-status-wrapper__payment-status"
              style={{
                color: `${checkStatus(orderDetails?.onlinePayment?.status)}`,
              }}
            >
              {orderDetails
                ? t(
                    `components:payment_status.${orderDetails?.onlinePayment.status}`
                  )
                : null}
            </div>
          </Components.BergStack>
          <div className="payment-status-wrapper__thank-you-message">
            {t('pages:order_confirmation.thank_you_message')}
          </div>
          <Components.ThanksForOrder />
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
          <Components.OrderContactData
            contactData={orderDetails?.contactData}
            companyData={orderDetails?.companyData}
          ></Components.OrderContactData>
          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
          {orderDetails?.skiPassOrder?.items.length > 0 && (
            <>
              <p className="payment-status-label">
                {t('common:table_label.passes')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.skiPassOrder.items}
                columns={skiPassTable}
              />
            </>
          )}
          {orderDetails?.accommodationOrder?.items.length > 0 && (
            <>
              <p className="payment-status-label">
                {t('common:table_label.accommodation')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.accommodationOrder.items}
                columns={accommodationTable}
              />
            </>
          )}

          {orderDetails?.parkingOrder?.items.length > 0 && (
            <>
              <p className="payment-status-label">
                {t('common:table_label.parking')}
              </p>
              <Components.OrderTable
                tableClassName="berg-pages-cart-table"
                data={orderDetails.parkingOrder.items}
                columns={parkingTable}
              />
            </>
          )}

          <Components.BergDevider color="ligth-grey"></Components.BergDevider>
          <Components.BergStack
            direction="row"
            justifyContent="space-between"
            className="payment-status-wrapper__buttons"
          >
            <Components.BergButton
              label={t('components:payment_status.back_to_main_page')}
              color="primary-dark"
              onClick={() =>
                (window.location.href = 'https://www.dlugapolana.com/')
              }
            ></Components.BergButton>
            <Components.OrderSummaryFooter orderDetails={orderDetails} />
          </Components.BergStack>
        </Components.BergStack>
      </div>
    </Components.BergPageContentWrapper>
  );
};

export default PaymentStatus;
