import { Trans, useTranslation } from 'react-i18next';
import Components from '../../components';

const LandingpageThanks = () => {
  const { t } = useTranslation('pages');

  return (
    <>
      <Components.Portal id="header">
        <div className="berg-page-landingpage__header">
          <h1>{t('landingpage.header')}</h1>
          <div className="berg-page-landingpage__header--promo">
            {t('landingpage.promo')}
          </div>
        </div>
      </Components.Portal>
      <div className="berg-page-landingpage-thanks">
        <Trans
          i18nKey="landingpage_thanks"
          ns="pages"
          components={{
            p: <p />,
            strong: <strong />,
            br: <br />,
          }}
        />
      </div>

      <Components.ThanksForOrder />
    </>
  );
};

export default LandingpageThanks;
