import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ParkingMediaData.scss';
import { IDataCarrierIdInput, IPickedTickets } from '../types';
import Input from './Input';

interface IProps {
  tickets: IPickedTickets[];
  hide: () => void;
  setPickedTickets: Dispatch<SetStateAction<IPickedTickets[]>>;
}

const ParkingMediaData = (props: IProps) => {
  const { tickets, hide, setPickedTickets } = props;

  const { t } = useTranslation('components');

  const initialInputsState = tickets.map((ticket) => ({
    ...ticket,
    value: ticket.dataCarrierId,
  }));

  const [inputsState, setInputsState] =
    useState<IDataCarrierIdInput[]>(initialInputsState);

  const onSave = () => {
    const setDataCarriersIds = (input: IDataCarrierIdInput) => {
      const assignDataCarrierIds = (ticket: IPickedTickets) => {
        if (ticket.uniqueId === input.uniqueId) {
          return { ...ticket, dataCarrierId: input.dataCarrierId };
        } else {
          return ticket;
        }
      };

      setPickedTickets((prev) => prev.map(assignDataCarrierIds));
    };

    inputsState.forEach(setDataCarriersIds);

    hide();
  };

  return (
    <div className="berg-components-media-data">
      <div className="berg-components-media-data-header">
        <h1 className="berg-components-media-data-header-text">
          {t('media_data.header_car')}
        </h1>
      </div>
      <div className="berg-components-media-data-form">
        {inputsState.map((ticket: any, index: number) => (
          <Input
            ticket={ticket}
            index={index}
            key={ticket.uniqueId}
            setInputsState={setInputsState}
            setPickedTickets={setPickedTickets}
          />
        ))}
      </div>

      <div className="berg-components-media-data-footer">
        <hr />

        <button type="button" onClick={onSave}>
          {t('media_data.submit')}
        </button>
      </div>
    </div>
  );
};

export default ParkingMediaData;
