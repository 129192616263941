import Status404 from './status-404/Status404';
import Start from './start/Start';
import NoScript from './no-script/NoScript';
import CookiesPolicy from './cookies-policy/CookiesPolicy';
import OrderStatus from './OrderStatus/OrderStatus';
import Activities from './activities/Activities';
import CartManagement from './cart/CartManagement';
import CartData from './cart/CartData';
import PaymentStatus from './payment/PaymentStatus';
import CartSummary from './cart/cart-summary/CartSummary';
import Landingpage from './landingpage/Landingpage';
import LandingpageForm from './landingpage/LandingpageForm';
import LandingpageThanks from './landingpage/LandingpageThanks';

const Pages = {
  Start,
  Status404,
  NoScript,
  CookiesPolicy,
  OrderStatus,
  Activities,
  CartManagement,
  CartData,
  PaymentStatus,
  CartSummary,
  Landingpage,
  LandingpageForm,
  LandingpageThanks,
};

export default Pages;
